<template>
  <div class="btn-group" :class="{'opacity-button': !isMe}">
    <button class="btn btnoutline dropdown-toggle" type="button"
    ref="departmentDropDown" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside">
    {{selectedList.length > 0 ? $t(`sabre.search-panel.${selectedList[0]}`) : $t("sabre.search-panel.department")}}
    </button>

    <select-department propsClassName="dropdown-menu durationflightdropdown1 dropdown-menu-end" type="dropdown" @finished="closeDropdown"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dropdownOpenState from '@/sabre/common/mixins/dropdownOpenState';

export default {
  name: 'desktop-dropdown-checkbox',
  mixins: [dropdownOpenState],
  components: {
    selectDepartment: () => import('./selectDepartment'),
  },
  computed: {
    ...mapGetters({
      selectedList: 'GET_SABRE_SELECTED_FLIGHT_DEPARTMENTS',
    }),
  },
  methods: {
    closeDropdown() {
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.departmentDropDown).hide();
    },
  },
};
</script>
